import Layout from '../../components/Layout/Layout';
import {Container, Row, Col, Button, Image } from 'react-bootstrap';
import CenterHeading from '../../components/Comman/CenterHeading';

import teamimage1 from '../../Static/teamimage1.png';
import teamimage2 from '../../Static/teamimage2.png';
import teamimage3 from '../../Static/teamimage3.png';
import teamimage4 from '../../Static/teamimage4.png';

import Opedifferent from '../../Static/ope_different.png';

import Aboutcontant from '../About/Aboutcontant';

function AboutPage (){  
return(
    <Layout>
        <div className='main_what_is_ope aboutSection'>
        

        <Aboutcontant 
            Opeheading="About Run The Tech" 
            Opedescription="At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum."
        />
        
        <div className='Ourteam_mainsection'>
        <Container>
            <Row className='align_item_center row-justifycenter'>
                <Col md={8}>
                    <div className='Ourteam_title'>
                        <h2>The RTT Team</h2>
                        <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et doloru</p>
                    </div>
                </Col>
            </Row>

            <Row>
                
                <Col md={3}>
                    <div className='ourteam_body'>
                        <Image  src={teamimage1} />
                        <h3>John Keh</h3>
                        <p>Founder</p>
                    </div>
                </Col>

                <Col md={3}>
                    <div className='ourteam_body'>
                        <Image  src={teamimage2} />
                        <h3>Andy Keh</h3>
                        <p>Founder & Chief Analytics Engineer</p>
                    </div>
                </Col>
                
                <Col md={3}>
                    <div className='ourteam_body'>
                        <Image  src={teamimage3} />
                        <h3>Mohammed El Amine</h3>
                        <p>Data Scientist & Software Engineer</p>
                    </div>
                </Col>

                <Col md={3}>
                    <div className='ourteam_body'>
                        <Image  src={teamimage4} />
                        <h3>Kanye</h3>
                        <p>Marketing, Communications & Community</p>
                    </div>
                </Col>
            </Row>
        </Container>
        </div>

       
        </div>
        
    </Layout>
)
}
export default AboutPage;