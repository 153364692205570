import { Container, Row, Col, Image } from 'react-bootstrap';
import { BrowserRouter as Router,   Link } from "react-router-dom";
import './style.scss';
import footerlogo from '../../Static/footerlogo.svg';
import footerbgImage from '../../Static/footerbg_top.svg';

function Footer() {
    return (
        <>
        <div className='FooterMainSection'>
            <div className='footer_bg_image'><Image  src={footerbgImage} alt="Logo" className='bsn_logo'/></div>
        <Container>
            <Row>
                <Col md={2} xs={6} >
                  <ul>
                    <li><Link className="footerlinks" to="/about">About Us</Link> </li>
                    <li><Link className="footerlinks" to="/Pricing">Pricing</Link> </li>
                    <li><Link className="footerlinks" to="/careers">Careers</Link> </li>
                  </ul>
                </Col>
                <Col md={2} xs={6} >
                  <ul>
                    <li><Link className="footerlinks" to="/services">Services</Link> </li>
                    <li><Link className="footerlinks" to="/examples">Examples</Link> </li>
                  </ul>
                </Col>
                <Col md={2} xs={6} >
                  <ul>
                    <li><Link className="footerlinks" to="/">Press</Link> </li>
                    <li><Link className="footerlinks" to="/Blog">Blog</Link> </li>
                  </ul>
                </Col>
                <Col md={2} xs={6} >
                  <ul>
                    <li><Link className="footerlinks" to="/contact">Contact Us</Link> </li>
                    <li><Link className="footerlinks" to="/faq">FAQ</Link> </li>
                  </ul>
                </Col>
            </Row>
            <Row className='footer_details'>
                <Col  className='footer_logo'>
                <Image  src={footerlogo} alt="Logo" className='bsn_logo'/>
                </Col>
                <Col  >
                <p>
                   Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </p>
                </Col>
            </Row>
            <Row className='Copyright_main_footer'>
                <Col md={6} xs={12}>
                 <ul className='Condition_links'>
                    <li><Link className="footerlinks" to="/Legalities">Legalities</Link> </li>
                    <li><Link className="footerlinks" to="/Cookiepolicy">Cookie Policy</Link> </li>
                 </ul>
                </Col>
                <Col md={6} xs={12}><p className='Copyright_footer text-right'>Copyright © 2022 Run The Chain</p> </Col>
            </Row>
        </Container>
        </div>
        </>
       ) 
}
export default Footer;