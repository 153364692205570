import {Row, Col, Container, Button, Image } from 'react-bootstrap';
import './style.scss';
import Makinglist from './Makinglist';
import CenterHeading from '../Comman/CenterHeading';
import {WOW} from 'wowjs';


import makeopeimageTop from '../../Static/img_top.png';
import makeopeimageMiddle from '../../Static/img_middle.png';
import makeopeimageBottam from '../../Static/img_bottam.png';

import IconFaster from '../../Static/iconFaster.svg';
import IconSecure from '../../Static/IconSecure.svg';
import IconCheaper from '../../Static/IconCheaper.svg';

function Makeope() {
    const wow = new WOW({
        // offset: 100,
        // mobile: false,
        // live: true
    })
    
    return (
        <>
        <Container>
            <CenterHeading 
            MainHeading= "What makes RTT different?" 
            MainDescription= "Run The Tech is a Web3 company first, focusing on decentralized applications that utilize blockchain infrastructure. RTT doesn’t just build the applications, we help you conceptialize, architect, iterate and maintain your visison. " 
            />
            <Row className='align-item-center'>
            <Col md={6} className='align-item-center'>
                <div className='align-left leftimageWidth whatmakesleftimage'>
                       <div className='topImageWhat wow fadeInDown'> <Image  src={makeopeimageTop}  /> </div>
                       <div className='middleImageWhat '> <Image  src={makeopeimageMiddle}  /> </div>
                       <div className='bottamImageWhat wow fadeInUp'> <Image  src={makeopeimageBottam}  /> </div> 
                </div>
            </Col>
            <Col md={6}>
                <Makinglist 
                imageMakinglist={IconFaster} 
                MakingListHeading = "Domain Expertise " 
                MakingListDescription = "We have been in the space since 2010 and have seen it all. " 
                timerdelay="0.3s"
                />

                <Makinglist 
                imageMakinglist={IconSecure} 
                MakingListHeading = "Bespoke" 
                MakingListDescription = "Your needs are unique and we offer high-touch solutions that deliver." 
                timerdelay="0.6s"
                />

                <Makinglist 
                imageMakinglist={IconCheaper} 
                MakingListHeading = "Cost-Effective" 
                MakingListDescription = "We have refined processes that speeds time-to-market while keeping costs low." 
                timerdelay="0.9s"
                />
            </Col>
            </Row>
        </Container>
        
        </>
       ) 
}
export default Makeope;