import Layout from '../../components/Layout/Layout';
import Detailswithimage from '../../components/Comman/Detailswithimage';
import Detailswithimageleft from '../../components/Comman/Detailswithimageleft';
import CenterHeading from '../../components/Comman/CenterHeading';
import Makeope from '../../components/Makeope/Makeope';
import FAQ from '../../components/FAQ/Faq';
import EthereumDevelopment from '../../Static/EthereumDevelopment.png';
import Getstartarrow from '../../Static/getstart_arrow.svg';
import Whatope from '../../components/WhatOPE/Whatope';
import ProjectsImage from '../../Static/Projects.png';
import OPETutorials from '../../Static/OPE_Tutorials.png';
import MarktingRTClogo from '../../Static/Markting_RTClogo.svg';
import ConsultingImg from '../../Static/Consultingimage.png';



function HomePage (){  
	return (
      <Layout>
		  <Detailswithimage 
		  heading = "Your Gateway to Web3 Development"
		  description = "Run The Tech is a one-stop solution for all of your Web3 needs."
		  imgSource ={EthereumDevelopment}
		  type={3}
		  btnName="Get Started"
		  iconarrow={Getstartarrow}
		  />

		  <Makeope />
		  <Whatope />
		
		 <div className='serviceOverviewHead'>
		  <CenterHeading 
            MainHeading= "Service Overview " 
            />
         </div>
          <div className='service-parent-section'>
		  <Detailswithimage 
		  heading = "Web3 Services" 
		  description = "Run The Tech has all you need to supercharge your blockchain startup!" 
		  imgSource ={ProjectsImage}
		  type={1} 
		  btnName="Learn More"		  
		  />

		  <Detailswithimageleft 
		  heading = "Web2 Services"
		  description = "We can handle all of the Web2 work as well!"
		  imgSource ={OPETutorials}
		  type={1}
		  btnName="Learn More"		  
		  />

		  <Detailswithimage 
		  heading = "Consulting"
		  description = "Sometimes you just need an extra set of eyes and ears to make sure you are heading in the right direction. We have consulted on hundreds of projects and are formal advisors in dozens of Web3 companies around the world. We have seen it all so you don’t need to. "
		  imgSource ={ConsultingImg}
		  type={4}
		  btnName="Book A consultation"		  
		  />

		  <div className='marketing_mainsection'>
		  <Detailswithimageleft 
		  heading = "Marketing"
		  description = "While Run The Tech is not a marketing company, our sister company Run The Chain can offer effective marketing at discounted rates. Not to mention the integration advantages through incorporating a more holistic approach to project development. Having product and markeing on the same page is a must!"
		  imgSource ={MarktingRTClogo}
		  type={3}
		  btnName="Run The Chain"		  
		  />
		  </div>

		  </div>


	  </Layout>
	 )
}
export default HomePage;