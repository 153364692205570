import {Row, Col, Container, Button, Image } from 'react-bootstrap';
import Serviceslist from './Serviceslist';
import './style.scss';
import {WOW} from 'wowjs';

function Servicescontantleft({Opeheading, Opedescription="", type=0, imgSourceOpe=""}) {
    const wow = new WOW({
    })
    wow.init();
    return(
    <>
    <div>
    <Container>
            <Row className='align_item_center'>

            <Col md={6}>
                    <div className='align-right imageRightsideDetail'>
                        <Image  src={imgSourceOpe} alt="Ethereum Development" />
                    </div>
                </Col>

                <Col md={6}>
                    <div className='opeleftside_contant leftside_contant'>
                        <h2 className='wow fadeInUp' data-wow-delay="0.3s">{Opeheading}</h2>
                        <p className='wow fadeInUp' data-wow-delay="0.4s">{Opedescription}</p>
                        
                    </div>
                </Col>
                
            </Row>
    </Container>
    </div>
    </>
    );
}
export default Servicescontantleft;