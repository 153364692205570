import {Accordion} from 'react-bootstrap';
import './style.scss';

function Accordionfaq({Questionfaq, Answerfaq, eventKey="0", timerdelay="" }){
    return(
   <>
   <Accordion defaultActiveKey= "0" className='wow fadeInUp' data-wow-delay={timerdelay}>
     <Accordion.Item eventKey={eventKey}>
      <Accordion.Header> {Questionfaq}</Accordion.Header>
        <Accordion.Body>
         {Answerfaq}
        </Accordion.Body>
      </Accordion.Item>
   </Accordion>
   </>
    )
}
export default Accordionfaq;