import Layout from '../../components/Layout/Layout';
import {Row, Col, Button } from 'react-bootstrap';
import CenterHeading from '../../components/Comman/CenterHeading';

import Opetopbanner from '../../Static/opetopbanner.png';
import Opedifferent from '../../Static/ope_different.png';
import Infograph from '../../Static/Infograph.png';


import Servicescontant from './Servicescontant';
import Servicescontantleft from './Servicescontantleft';
import Serviceswhatcan from './Serviceswhatcan';
import Servicesmigration from './Servicesmigration';

function ServicesPage (){  
return(
    <Layout>
        <div className='main_what_is_ope services_page'>
        <div className='services_head_section'>
        <CenterHeading 
            MainHeading= "Web3 Services " 
            MainDescription= "Blockchains, Crypto, Smart Contracts, NFTs, DApps - RTT has you covered"
        />
        </div>

        <Servicescontant imgSourceOpe={Infograph}
            Opeheading="Blockchain Architecture" 
            Opedescription="Our development team works with you from the ground up: assessing design solutions, requirement mapping, and consulting you on product features. Next, we set and ensure best practices for all aspects of the blockchain platform, including security and data standards compliance. We then develop the capabilities and turn requirements for Blockchain-based products into architected solutions to enable high-value offerings in one or more ABC services."
        />
        <Servicescontantleft imgSourceOpe={Infograph}
            Opeheading="Smart Contract Development " 
            Opedescription="Our team can write all manners of smart contracts on a multitude of chains. We can also help you explore smart contract variations that will approach your unique problems in different ways."
        />
        <Servicescontant imgSourceOpe={Infograph}
            Opeheading="Smart Contract DeFi Project Builds" 
            Opedescription="Got an DeFi project that needs built, architected, deployed or audited? Run The Tech can help bring your platform to life. With experienced DeFi developers and practitioners located around the world, we deliver the highest quality of work in the most effective amount of time."
        />
        <Servicescontantleft  imgSourceOpe={Infograph}
            Opeheading="Launchpad" 
            Opedescription="We can also help you navigate raising capital while saving money and time. Run The Tech offers white-labeled launchpad services. Our launchpad is cross-chain and can run custom campaigns targeting retail, KOLs, or VCs."
        />

       <div className='services_head_sec-section'>
        <CenterHeading 
            MainHeading= "Web2 Services" 
            MainDescription= "Anything on the internet that isn’t Web3-- RTT has you covered here, too!"
        />
        </div>

        <Servicescontantleft imgSourceOpe={Infograph}
            Opeheading="Website Builds" 
            Opedescription="You never get a second chance to make a first impression. And there’s no better way to make a lasting impression than with a professional-looking website. Run The Tech provides turn-key solutions to help you design, build, and launch the website of your dreams. Couple that with fast loading speeds, a google-friendly structure, SEO friendly, and pages optimized for all devices, and you’ve got a first impression that sells itself."
        />

        <Servicescontant imgSourceOpe={Infograph}
            Opeheading="Design / UX/UI" 
            Opedescription="We have a stellar team of in-house designers and UX/UI experts who put the user first. Modern user experiences require modern technology and best-in-class practices. We can help you build high conversion technology "
        />
        <Servicescontantleft  imgSourceOpe={Infograph}
            Opeheading="Tech Audit" 
            Opedescription="We’re methodical and thorough in cataloguing your technological capacity and needs. From operational status, asset value and disaster readiness to identifying and resolving bad code and optimising data flow - we ensure that your operation’s technological architecture is the best it can be."
        />


        <Servicescontant imgSourceOpe={Infograph}
            Opeheading="SEO Audit" 
            Opedescription=" Our comprehensive SEO analysis assesses your site’s strengths and weaknesses, looking at the broader impact you’re having - domain authority and backlinks - essentials like keyword rankings and traffic, to the finer details such as page speeds, link structure, image optimisation, crawl errors and much, much more."
        />
        <Servicescontantleft  imgSourceOpe={Infograph}
            Opeheading="Social Media Audit" 
            Opedescription="We employ a versatile range of social listening and scanning tools to give you full picture your social media reputation. We can help you with sharing content in a dynamic, engaging way; improve brand awareness, boost conversions and help you understand what your audience is saying and how. "
        />


        <Servicescontant imgSourceOpe={Infograph}
            Opeheading="Maintenance" 
            Opedescription="After we’ve built out your blockchain project from scratch, we don’t just leave it there! We also provide maintenance services that keep everything we’ve built running, optimized, and secure. Think of us like your extended team, working behind the scenes to ensure you can focus on what you do best: build your business and satisfy your clients!"
        />

        <Servicesmigration/>
        </div>
        
    </Layout>
)
}
export default ServicesPage;