
import {Container, Row, Col, Button, Image } from 'react-bootstrap';
import CenterHeading from '../../components/Comman/CenterHeading';
import './style.scss';

function Portfoliolist ({Portfoliolink, Portfolioheading, Portfoliodescription, Portfolioimage}){  
return(
   
        
            <div className='Portfolio_mainsection'>
                <div className='Portfolio_inner_image'>
                    <Image src={Portfolioimage} />
                </div>
                <div className='Portfolio_inner_content'>
                    <h3>{Portfolioheading}</h3>
                    <p>
                        {Portfoliodescription}
                    </p>
                    <a href={Portfoliolink} className='btn btn-readmore' target='_blank'>Website Link</a>
                </div>
            </div>
        
)
}
export default Portfoliolist;