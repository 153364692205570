import axios from "axios";
import { API_URL } from "./api";
export const functionService = {
    postData,
    validateError
};
function postData(formData,apipath){
    return axios({
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        url: API_URL+apipath,
        data:  formData
      }).then(function (response) {
        if(response.status === 200){
            if(response.data.hasOwnProperty("status") && response.data.status !== 'subscribed'){
                return {status:false,message:response.data.title}    
            }else{
                return {status:true,message:'Successfully submitted your details !'}    
            }
        }else{
            return {status:false,message:'Some error occured!'}
        }
      })
    .catch((error)=> {
          return {status:false,message:'Some error occured!'}
      }); 
}
function validateError(formData){
    let errors = {};
    if(formData.email_address === ""){
        errors['email_address']=true;
    }
    Object.keys(formData.merge_fields).forEach(element=>{
        if(formData.merge_fields[element] === ""){
            errors[element]=true;
        }
    })
    return errors
}