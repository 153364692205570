import { Image } from 'react-bootstrap';
import './style.scss';

function Makinglist({imageMakinglist, MakingListHeading, MakingListDescription, timerdelay=""}) {
    return(
        <>
        <div className='Makinglist_box wow fadeInUp' data-wow-delay={timerdelay} >
            <div className="IconMakinglist">
                <Image  src={imageMakinglist} alt="Faste Icon" />
            </div>
            <div className='ContantMakinglist'>
                <h3>{MakingListHeading}</h3>
                <p>{MakingListDescription}</p>
            </div>
        </div>
        </>
    )
}
export default Makinglist;