import './style.scss';

function Tabcotantdetails({Subheading, Subdescription, Subdescription2=""}) {
    return(
        <>
        <div className="subheading_tabdetails">
            <h3>{Subheading}</h3>
            <p>{Subdescription}</p>
            <p>{Subdescription2}</p>
        </div>
        </>
    )
}
export default Tabcotantdetails;