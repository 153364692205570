import {Image } from 'react-bootstrap';
import listicon from '../../Static/checkcircle.svg';

function Serviceslist({Opelistcontant=""}){
    return(
            <>
            <p className='list_details'><span><Image  src={listicon} alt="Icon list" /></span>{Opelistcontant}</p>
            </>
    )
}
export default Serviceslist;