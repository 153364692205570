import {Row, Col, Container, Button, Image } from 'react-bootstrap';
import './style.scss';
import Buttons from './Button';
import {WOW} from 'wowjs';
import {Link} from 'react-router-dom';

function Detailswithimage ({heading, description ="", imgSource, type=0, btnName, iconarrow="" }){  

    const wow = new WOW({

    })
    wow.init();
wow.sync();
    
	return (
     <>
        <div className="DetailSection">
        <Container>
            <Row>
            <Col md={6} className='imageRightsideDetailParent'>
                
                    <div className='align-right imageRightsideDetail'>
                       <div className='wow fadeInUp' data-wow-delay="0.3s"> <Image src={imgSource} alt="Ethereum Development" /> </div>
                    </div>
                
                </Col>
                <Col md={6} className='align_item_center'>
                    <div className='leftside_contant'  >
                        <h2 className='wow fadeInUp' data-wow-delay="0.3s">{heading}</h2>
                        <p className='wow fadeInUp' data-wow-delay="0.4s">{description}</p>
                        {type===1 && <>
                         <ul>
                             <ol className='wow fadeInUp' data-wow-delay="0.3s"><span className='lisnumber'>1</span>Introduction</ol>
                             <ol className='wow fadeInUp' data-wow-delay="0.7s"><span className='lisnumber'>2</span>Non-Fungible Token (ERC-721 and ERC-1155)</ol>
                             <ol className='wow fadeInUp' data-wow-delay="0.9s"><span className='lisnumber'>3</span>Getting Started with the Required Setup</ol>
                             <ol className='wow fadeInUp' data-wow-delay="1s"><span className='lisnumber'>4</span>Storing files in Pinata IPFS Cloud</ol>
                         </ul>
                            <div className='wow fadeInUp' data-wow-delay="0.5s" >
                                 <Link className='btn btn_bsn_blue' to="/services" >{btnName}</Link>
                            </div>                         
                         </>}
                         {type===2 && <>
                            {/* <ul>
                                <ol className='wow fadeInUp' data-wow-delay="0.3s"><span className='lisnumber'>1</span>Artificial Intelligence </ol>
                                <ol className='wow fadeInUp' data-wow-delay="0.7s"><span className='lisnumber'>2</span>Website Development</ol>
                                <ol className='wow fadeInUp' data-wow-delay="0.9s"><span className='lisnumber'>3</span>Maintenance</ol>
                                <ol className='wow fadeInUp' data-wow-delay="1s"><span className='lisnumber'>4</span>Storing files in Pinata IPFS Cloud</ol>
                            </ul> */}
                        </>}
                         {type===3 &&
                            <div className='wow fadeInUp' data-wow-delay="0.5s" >
                                <Link className='btn btn_bsn_blue' to="/about" >{btnName}</Link>
                            </div>
                         }
                         {type===4 &&
                            <div className='wow fadeInUp' data-wow-delay="0.5s" >
                                <Link className='btn btn_bsn_blue' to="/contact" >{btnName}</Link>
                            </div>
                         }
                          
                               
                    </div>
                </Col>
                
            </Row>
        </Container>
        </div>
     </>
	 )
}
export default Detailswithimage;