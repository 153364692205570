
import Layout from '../../components/Layout/Layout';
import {Row, Col, Container, Button, Image, Tabs, Tab } from 'react-bootstrap';
import {useState} from "react";
import './style.scss';
import Accordionfaq from '../../components/FAQ/Accordionfaq';
import {WOW} from 'wowjs';

function FAQpage() {
    const wow = new WOW({
    })
    wow.init();
    return(
        <Layout>
        <div className='FAQpage_main'>
            <Container>
                <Row>
                    <Col className='mainSection_dapps'>
                        <h3>FAQ</h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <div className='FAQpageBody'>
                    <Tabs defaultActiveKey="General" id="uncontrolled-tab-example" className="tabs_faq">
                        <Tab eventKey="General" title="General" className='tabs_faq_bodypart' >
                            
                            <Accordionfaq Questionfaq="How long do I wait to get a reply?" 
                            Answerfaq= "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollit." 
                            />
                            <Accordionfaq eventKey="1" Questionfaq="Who Run Bartertown?"   
                            Answerfaq= "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollit." 
                            />
                            <Accordionfaq eventKey="2" Questionfaq="Can RTT deploy an application cross-chain?"  
                            Answerfaq= "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollit." 
                            />

                        </Tab>
                        <Tab eventKey="Development" title="Development"  className='tabs_faq_bodypart'>
                            
                            <Accordionfaq  Questionfaq="How long do I wait to get a reply?"    
                            Answerfaq= "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollit." 
                            />
                            <Accordionfaq eventKey="1" Questionfaq="Who Run Bartertown?"   
                            Answerfaq= "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollit." 
                            />
                            <Accordionfaq eventKey="2" Questionfaq="Can RTT deploy an application cross-chain?"   
                            Answerfaq= "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollit." 
                            />
                            

                        </Tab>
                        <Tab eventKey="Pricing" title="Pricing"  className='tabs_faq_bodypart'>

                            <Accordionfaq  Questionfaq="How long do I wait to get a reply?"  
                            Answerfaq= "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollit." 
                            />
                            <Accordionfaq eventKey="1" Questionfaq="Who Run Bartertown?" 
                            Answerfaq= "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollit." 
                            />
                            <Accordionfaq eventKey="2" Questionfaq="Can RTT deploy an application cross-chain?"  
                            Answerfaq= "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollit." 
                            />
                            

                        </Tab>
                    </Tabs>
                    </div>
                    </Col>
                </Row>
            </Container>
        </div>
        </Layout>
    )
}
export default FAQpage;