import {Row, Col, Container, Button, Image } from 'react-bootstrap';
import './style.scss';
import {WOW} from 'wowjs';
import {Link} from 'react-router-dom';

function Aboutcontant({Opeheading, Opedescription="", imgSourceOpe=""}) {
    const wow = new WOW({
    })
    wow.init();
    return(
    <>
    <div>
    <Container>
            <Row className='align_item_center aboutContentSection'>
                <Col md={6}>
                    <div className='opeleftside_contant leftside_contant'>
                        <h2 className='wow fadeInUp' data-wow-delay="0.3s">{Opeheading}</h2>
                        <p className='wow fadeInUp' data-wow-delay="0.4s">{Opedescription}</p>
                       
                    </div>
                </Col>
                <Col md={6}>
                    <div className='align-center'>
                        <Link className='btn btn_bsn_blue' to="/contact" >Contact US</Link>
                    </div>
                </Col>
            </Row>
    </Container>
    </div>
    </>
    );
}
export default Aboutcontant;