import React from 'react';
import Form from 'react-bootstrap/Form';
import './style.scss';

function Translation() {
    return (
        <>
            <div className='Header_Language'>
                <Form.Select aria-label="Default select example">
                <option>English</option>
                <option value="1">French</option>
                <option value="2">Chinese</option>
                </Form.Select>
            </div>
        </>
       ) 
}
export default Translation;