import React,{useState} from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { UserContext } from './lib/UserContext';
import './App.css';
import HomePage from "./Pages/Home/HomePage";
import AboutPage from './Pages/About/AboutPage';

import opetutorials from './Pages/Tutorial/TutorialOPE';
import FAQpage from './Pages/FAQ/FAQpage';
import Pricing from './Pages/Pricing/Pricing';
import Contact from './Pages/Contact/Contact';
import ServicesPage from './Pages/Services/Servicespage';
import Portfolio from './Pages/Portfolio/Portfolio';

function App() {
	const [loginUser, setLoginUser] = useState(null);
  return (
    <UserContext.Provider value={[loginUser, setLoginUser]}>
    <Router>
      <Switch>
          <Route
          exact
          path="/"
          render={() => <Redirect to="/home" />}
        />
        <PublicRoute path="/home" component={HomePage} />
        <PublicRoute path="/about" component={AboutPage} />Services
        <PublicRoute path="/services" component={ServicesPage} />
        
        <PublicRoute path="/learning" component={opetutorials} />

        <PublicRoute path="/faq" component={FAQpage} />  
        <PublicRoute path="/pricing" component={Pricing} />  
        <PublicRoute path="/contact" component={Contact} /> 
        <PublicRoute path="/portfolio" component={Portfolio} />  

       </Switch>
    </Router>
	 </UserContext.Provider>
  );
  
  function PublicRoute({ component, ...rest } : any) {
    return (
      <Route
        {...rest}
        render={props =>
         (
            React.createElement(component, props)
          )
        }
      />
    );
  }
  function PrivateRoute({ component, ...rest } : any) {
    return (
	localStorage.getItem('checkLogin') === 'solpulseadmin' ?
      <Route
        {...rest}
        render={props =>
         (
            React.createElement(component, props)
          )
        }
      />
	  :
	  <Redirect to="/login" />
	  
    );
  }
}

export default App;
