import Layout from '../../components/Layout/Layout';
import {Container, Row, Col, Button, Image } from 'react-bootstrap';
import CenterHeading from '../../components/Comman/CenterHeading';
import Portfoliolist from './Portfoliolist';
import './style.scss';
import Blockchains1 from '../../Static/Blockchains1.png';
import Blockchains2 from '../../Static/Blockchains2.png';

function Portfolio (){  
return(
    <Layout>
        <div className='main_what_is_ope'>
        
        <div className='portfolio_mainsection'>
            <CenterHeading 
                MainHeading= "Portfolio " 
            />
            <Container>
                <Row className='align_item_center row-justifycenter'>
                    <Col md={12}>

                       <Portfoliolist Portfoliolink="https://synchrony.fi/"
                       Portfolioimage={Blockchains1}
                       Portfolioheading="Synchrony" 
                       Portfoliodescription="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book." 
                       />

                       <Portfoliolist Portfoliolink="#"
                       Portfolioimage={Blockchains2}
                       Portfolioheading="Binance Smart Chain" 
                       Portfoliodescription="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book." 
                       />

                    </Col>
                </Row>
            </Container>
        </div>

       
        </div>
        
    </Layout>
)
}
export default Portfolio;