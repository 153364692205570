import { Row, Col, Container, Image, Button } from 'react-bootstrap';
import imgwhatope from '../../Static/Computer.png';
import bgWhatope from '../../Static/bg-whatope.png';
import './style.scss';
import {Link} from 'react-router-dom';

function Whatope() {
    return(
        <>
        <div className='whatope_main'>
            {/* <div className='background_image_ope'><Image  src={bgWhatope} alt="what OPE Computer" /></div> */}
        <Container>
            <Row>
             <Col md={6} className='SectionRuntech'>
                    <div className='rightsideimage_whatope text-center imageRightsideDetail'>
                        <div className='wow fadeInUp' data-wow-delay="0.5s"> 
                            <Image  src={imgwhatope} alt="what OPE Computer" />
                        </div>
                    </div>
                </Col>
                <Col md={6} className='align_item_center'>
                    <div className="leftcontant_whatope">
                        <h2 className='wow fadeInUp' data-wow-delay="0.3s">What is Run The Tech?</h2>
                        <p className='wow fadeInUp' data-wow-delay="0.4s">
                        RTT is an international team of seasoned Web3 Builders, designers, architects, and product specialists. If you have an idea you want us to explore or an existing project you need refined look no further. 
                        </p>
                        <div className='wow fadeInUp' data-wow-delay="0.5s" >
                             <Link className='btn btn_bsn_white' to="/about" > Learn more about RTT </Link>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
        </div>
        </>
    )
}
export default Whatope;