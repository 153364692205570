import {Row, Col, Container, Image } from 'react-bootstrap';
import './style.scss';
import listicon from '../../Static/checkcircle.svg';
import CenterHeading from '../../components/Comman/CenterHeading';
import Blockchains1 from '../../Static/Blockchains1.png';
import Blockchains2 from '../../Static/Blockchains2.png';
import Blockchains3 from '../../Static/Blockchains3.png';
import Blockchains4 from '../../Static/Blockchains4.png';
import Blockchains5 from '../../Static/Blockchains5.png';
import Blockchains6 from '../../Static/Blockchains6.png';
import Blockchains7 from '../../Static/Blockchains7.png';
import Blockchains8 from '../../Static/Blockchains8.png';
import Blockchains9 from '../../Static/Blockchains9.png';



function Servicesmigration() {
    return(
    <>
    <div className='main_migration'>
        <Container>
            <CenterHeading 
            MainHeading="Blockchains we Build on" 
            />
                <Row className="justify-content-md-center">
                    <Col md={12}>
                        <div className='opeleftside_contant leftside_contant'>
                           <div className='migrationImageList wow fadeInUp'   data-wow-delay="0.7s">
                                <ul>
                                    <li> <Image  src={Blockchains1} alt="Tools" /></li>
                                    <li> <Image  src={Blockchains2} alt="Tools" /></li>
                                    <li> <Image  src={Blockchains3} alt="Tools" /></li>
                                    <li> <Image  src={Blockchains4} alt="Tools" /></li>
                                    <li> <Image  src={Blockchains5} alt="Tools" /></li>
                                    <li> <Image  src={Blockchains6} alt="Tools" /></li>
                                    <li> <Image  src={Blockchains7} alt="Tools" /></li>
                                    <li> <Image  src={Blockchains8} alt="Tools" /></li>
                                    <li> <Image  src={Blockchains9} alt="Tools" /></li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                   
                </Row>
        </Container>
    </div>
    </>
    );
}
export default Servicesmigration;