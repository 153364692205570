import { useState } from 'react';
import {Image } from 'react-bootstrap';
import SearchIcon from '../../Static/searchicon.svg';
import './style.scss';


function Search() {
    const[show, setShow] = useState(false);
    const handleSearch = () =>{
        setShow(!show);
    }
    return (
        <>
        <div className='header_search'>
            <Image  src={SearchIcon} alt="Searchicon" onClick={handleSearch}/>
            {show &&
            <div className='searchheader_box '>
            <form>
                <div className="form-group align-items-center">
                    <input type="text" className="form-control" id="Searchheader" placeholder="Search" />
                    <button type="submit" className="btn btn-primary"> <Image  src={SearchIcon} alt="Searchicon" /></button>
                </div>
            </form>
            </div>
            }
        </div>
        </>
       ) 
}
export default Search;