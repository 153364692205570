import Header from "../Header/Header";
import Footer from "../Footer/Footer";

function Layout (props){
    const {children} = props;
     
	return (
      <>
		<Header/>
            <div className="main-container">
                {children}
            </div>
        <Footer/>
	  </>
	 ) 
}
export default Layout;