

function Tabsmainheading ({Heading, Description, Description2="" }){  
	return (
      <>
      <div className="rightside_tabs">
          <h2>{Heading} </h2>
          <p> {Description}</p>

          <p> {Description2}</p>
      </div>
      </>
	 )
}
export default Tabsmainheading;